module.exports = {
    OPPORTUNITY_ID: "opportunity_id",
    DEMAT_ACCOUNT_TYPE: "demat_account_type",
    INVESTMENT_GOALS: "investment_goals",
    PORTFOLIO_SIZE: "portfolio_size",
    IS_ONBOARDING_DOCUMENT: "is_onboarding_document",
    OPPORTUNITY_SLUG: "opportunity_slug",
    COUNTER_PARTY: "counter_party",
    ASSET_CLASS: "asset_class",
    ISIN: "isin",
    IRR: "irr",
    TENURE: "tenure",
    INVESTED_AMOUNT: "invested_amount",
    FORM_ID: "form_id",
    SURVEY_LINK_ID: "survey_link_id",
    STATUS_CODE: "status_code",
    PATH: "path",
    PRODUCT_SLUG: "product_slug",
    BLOG_SLUG: "blog_slug",
    INVESTMENT_TIME_FRAME: "investment_time_frame",
    ISSUER_NAME: "issuer_name",
    NEWS_LETTER_ID: "news_letter_id",
    BANK_NAME: "bank_name",
    PAYMENT_METHOD: "payment_method"
};
