import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import { getWithAuthDecorator, hasWindow, postWithAuthDecorator } from '@services/http.service';
import {
    CREATE_INVESTMENT_URL,
    GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL,
    GET_INVESTOR_INVESTMENTS_URL,
} from '@services/urls/investment';
import {
    ADD_OPPORTUNITY_PAYMENT_SCHEDULE_URL,
    ADD_WALLET_AMOUNT_URL,
    ADMIN_LIST_MULTIPLE_BANK_MANUAL_VERIFICATION,
    ADMIN_LIST_PENDING_INVESTOR_DEMATS,
    BULK_UPLOAD_PENDING_INVESTOR_DEMATS_BY_ADMIN,
    CREATE_ADDRESS_INVESTOR_DOCUMENT_FROM_ADMIN_URL,
    CREATE_ADDRESS_INVESTOR_DOCUMENT_URL,
    CREATE_INVESTOR_BANK_DETAILS_URL,
    CREATE_INVESTOR_DECLARATION,
    CREATE_INVESTOR_DEMAT,
    CREATE_INVESTOR_DOCUMENT_URL,
    CREATE_INVESTOR_PAN_VALIDATION,
    CREATE_INVESTOR_URL,
    CREATE_INVESTOR_WALLET_URL,
    CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS,
    CREATE_PAN_INVESTOR_DOCUMENT_URL,
    CREATE_PAN_INVESTOR_DOCUMENT_V2_URL,
    CREATE_USER_PERMISSION_URL,
    DOWNLOAD_INVESTORS_FOR_OPPORTUNITY,
    DOWNLOAD_PENDING_INVESTOR_DEMATS_BY_ADMIN,
    EDIT_ADDRESS_DETAILS_URL,
    EDIT_BANK_DETAILS_URL,
    EDIT_INVESTOR_ADDRESS_DOCUMENT_URL,
    EDIT_PANCARD_DETAILS_URL,
    GET_BANK_DETAILS_URL,
    GET_CONFIGURATIONS_URL,
    GET_DEPOSITORY_PARTICIPANTS_DEMAT_URL,
    GET_DROPPED_OFF_INVESTORS_URL,
    GET_EXISTING_INVESTOR_URL,
    GET_INVESTORS_ESIGN_KYC_FILENAMES_URL,
    GET_INVESTORS_ESIGN_KYC_URL,
    GET_INVESTORS_FOR_KYC_URL,
    GET_INVESTORS_URL,
    GET_INVESTOR_DEMAT_URL,
    GET_INVESTOR_DOCUMENT_URL,
    GET_INVESTOR_ESIGN_FORM_URL,
    GET_INVESTOR_KYC_NUDGE,
    GET_INVESTOR_KYC_STATUS,
    GET_INVESTOR_PAN_VALIDATION,
    GET_INVESTOR_RELATIONSHIP_MANAGER_DETAILS,
    GET_INVESTOR_URL,
    GET_OPPORTUNITY_PAYMENT_SCHEDULE_URL,
    MARK_BANK_DETAILS_STATUS_URL,
    MARK_INVESTOR_DEMAT_DETAILS_STATUS_URL,
    MARK_INVESTOR_DOCUMENT_STATUS_URL,
    POST_INVESTOR_PAN_HVOCR,
    UPDATE_BULK_OPPORTUNITY_PAYMENT_SCHEDULE_URL,
    UPDATE_INVESTOR_DEMAT,
    UPDATE_INVESTOR_EXCHANGE_ACCOUNT,
    UPDATE_INVESTOR_INVITATION_BANK_DETAILS_URL,
    UPDATE_INVESTOR_OVERVIEW_COMPLETED,
    UPDATE_MIGRATED_INVESTOR_BANK_DETAILS,
    UPDATE_MIGRATED_INVESTOR_DEMAT_DETAILS,
    UPDATE_MOBILE_URL,
    UPDATE_NEW_EMAIL_URL,
    UPDATE_OPPORTUNITY_PAYMENT_SCHEDULE_BY_PAYMENT_ID_URL,
    UPDATE_VERIFY_INVESTOR_DEMAT_BY_ADMIN,
    GET_INVESTOR_NOMINEE,
    INVESTOR_NOMINEE_URL,
    GET_INVESTOR_AMO_NUDGE,
    GET_INVESTOR_URL_V2,
    GET_BULK_OPPORTUNITY_PAYMENT_SCHEDULES_URL,
    BULK_UPLOAD_OPPORTUNITY_PAYMENT_SCHEDULE_FILE,
    DOWNLOAD_PAYMENT_SCHEDULE_BY_OPPORTUNITY_ID,
} from '@services/urls/investor';
import {
    CREATE_PAYMENT_URL,
    GET_PAYMENT_HISTORY_URL,
    GET_PAYMENT_URL,
} from '@services/urls/payment';

export const validate = (mobile) => {
    const url = GET_EXISTING_INVESTOR_URL(mobile);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url);
};

export const createInvestor = (data, ctx = null) => {
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(CREATE_INVESTOR_URL(), data);
    }
    return SsrHttpService.postWithAuth(CREATE_INVESTOR_URL(), data, ctx);
};

export const createInvestorWithAuthDecorator = (data, auth) => {
    return postWithAuthDecorator(CREATE_INVESTOR_URL(), data, auth);
}

export const createInvestment = (
    promisedAmount,
    investorId,
    opportunityId,
    ctx = null
) => {
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(
            CREATE_INVESTMENT_URL(),
            { promisedAmount, investorId, opportunityId },
            ctx
        );
    }
    return SsrHttpService.postWithAuth(
        CREATE_INVESTMENT_URL(),
        { promisedAmount, investorId, opportunityId },
        ctx
    );
};

export const adminListPendingInvestorDemat = (
    page,
    limit,
    searchText,
    searchPanText,
    ctx = null
) => {
    const url = ADMIN_LIST_PENDING_INVESTOR_DEMATS(page, limit, searchText, searchPanText);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const adminListMultipleBankManualVerification = (
    page,
    limit,
    personalDetailsSearch,
    panSearch,
    ctx = null
) => {
    const url = ADMIN_LIST_MULTIPLE_BANK_MANUAL_VERIFICATION(page, limit, personalDetailsSearch, panSearch);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestor = (id, ctx = null) => {
    const url = GET_INVESTOR_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorWithAuthDecorator = (id, auth) => {
    const url = GET_INVESTOR_URL(id);
    return getWithAuthDecorator(url, auth);
};

export const getInvestorV2 = (id, ctx = null) => {
    const url = GET_INVESTOR_URL_V2(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);

}

export const updateNewEmail = (data, ctx = null) => {
    const url = UPDATE_NEW_EMAIL_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createInvestorDocument = (data, ctx = null) => {
    const url = CREATE_INVESTOR_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createInvestorBankDetails = (data, ctx = null) => {
    const url = CREATE_INVESTOR_BANK_DETAILS_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

// Start from here
export const getInvestors = (
    page,
    limit,
    searchText,
    searchPanText,
    ctx = null
) => {
    const url = GET_INVESTORS_URL(page, limit, searchText, searchPanText);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestmentByInvestorOpportunity = (
    investorId,
    opportunityId,
    ctx = null
) => {
    const url = GET_INVESTMENT_BY_ENTITY_OPPORTUNITY_URL(
        investorId,
        opportunityId
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const editPanCardDetails = (id, data, ctx = null) => {
    const url = EDIT_PANCARD_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const editBankDetails = (id, data, ctx = null) => {
    const url = EDIT_BANK_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getBankDetails = (id, masked, ctx = null) => {
    const url = GET_BANK_DETAILS_URL(id, masked);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const editAddressDetails = (id, data, ctx = null) => {
    const url = EDIT_ADDRESS_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const markInvestorDocumentStatus = (id, data, ctx = null) => {
    const url = MARK_INVESTOR_DOCUMENT_STATUS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const markBankDetailsStatus = (id, data, ctx = null) => {
    const url = MARK_BANK_DETAILS_STATUS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const markInvestorDematDetailsStatus = (id, data, ctx = null) => {
    const url = MARK_INVESTOR_DEMAT_DETAILS_STATUS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const createPayment = (data, ctx = null) => {
    const url = CREATE_PAYMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getPayment = (opportunityId, entityId, ctx = null) => {
    const url = GET_PAYMENT_URL(opportunityId, entityId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getPaymentHistory = (id, opportunityId, orderId, ctx = null) => {
    const url = GET_PAYMENT_HISTORY_URL(id, opportunityId, orderId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityPaymentScheduleDetails = (
    opportunityId,
    status = [],
    ctx = null
) => {
    const url = GET_OPPORTUNITY_PAYMENT_SCHEDULE_URL(opportunityId, status);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateOpportunityPaymentScheduleDetails = (
    paymentId,
    data,
    ctx = null
) => {
    const url =
        UPDATE_OPPORTUNITY_PAYMENT_SCHEDULE_BY_PAYMENT_ID_URL(paymentId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const addOpportunityPaymentScheduleDetails = (data, ctx = null) => {
    const url = ADD_OPPORTUNITY_PAYMENT_SCHEDULE_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const bulkUploadOpportunityPaymentScheduleFile = (data, ctx = null) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('fileName', data.fileName);
    formData.append('opportunityId', data.opportunityId);
    formData.append('outOfScheduleData', JSON.stringify(data.outOfScheduleData));
    const url = BULK_UPLOAD_OPPORTUNITY_PAYMENT_SCHEDULE_FILE();
    if (hasWindow()) {
        return CsrHttpService.postWithAuthMultipart(url, formData);
    }
    return SsrHttpService.postWithAuthMultipart(url, formData, ctx);
};

export const UpdateBulkOpportunityPaymentScheduleDetails = (
    data,
    opportunityId,
    ctx = null
) => {
    const url = UPDATE_BULK_OPPORTUNITY_PAYMENT_SCHEDULE_URL(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const editInvestorAddressDocument = (id, data, ctx = null) => {
    const url = EDIT_INVESTOR_ADDRESS_DOCUMENT_URL(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getConfigurations = (
    configurationType,
    entityType,
    ctx = null
) => {
    const url = GET_CONFIGURATIONS_URL(configurationType, entityType);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorsForKyc = (
    {
        page,
        limit,
        searchText = '',
        searchPanText = '',
        kycStatus = '',
        nseWorkflowStatus = '',
    },
    ctx = null
) => {
    const url = GET_INVESTORS_FOR_KYC_URL(
        page,
        limit,
        searchText,
        searchPanText,
        kycStatus,
        nseWorkflowStatus
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorInvestments = (id, ctx = null) => {
    const url = GET_INVESTOR_INVESTMENTS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
export const createUserPermission = (data, ctx = null) => {
    const url = CREATE_USER_PERMISSION_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createUserPermissionWithAuthDecorator = (data, auth) => {
    return postWithAuthDecorator(CREATE_USER_PERMISSION_URL(), data, auth);
};

export const getDroppedOffInvestors = (
    opportunityId,
    page,
    limit,
    ctx = null
) => {
    const url = GET_DROPPED_OFF_INVESTORS_URL(opportunityId, page, limit);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const downloadInvestorsForOpportunity = (opportunityId, ctx = null) => {
    const url = DOWNLOAD_INVESTORS_FOR_OPPORTUNITY(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url);
    }
    return SsrHttpService.postWithAuth(url, ctx);
};

export const getInvestorProfileDetails = (investorId, ctx = null) => {
    const url = CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createInvestorProfileDetails = (data, ctx = null) => {
    const url = CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS(data.investorId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const updateInvestorProfileDetails = (data, ctx = null) => {
    const url = CREATE_OR_UPDATE_INVESTOR_PROFILE_DETAILS(data.investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateInvitedInvestorBankDetails = (
    data,
    bankDetailsId,
    ctx = null
) => {
    const url = UPDATE_INVESTOR_INVITATION_BANK_DETAILS_URL(bankDetailsId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateMobile = (data, ctx = null) => {
    const url = UPDATE_MOBILE_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};
export const createPanInvestorDocument = (data, ctx = null) => {
    const url = CREATE_PAN_INVESTOR_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createPanInvestorDocumentV2 = (data, ctx = null) => {
    const url = CREATE_PAN_INVESTOR_DOCUMENT_V2_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createAddressInvestorDocument = (data, ctx = null) => {
    const url = CREATE_ADDRESS_INVESTOR_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createAddressInvestorDocumentFromAdmin = (
    investorId,
    data,
    ctx = null
) => {
    const url = CREATE_ADDRESS_INVESTOR_DOCUMENT_FROM_ADMIN_URL(investorId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getInvestorDocument = (investorId, type, ctx = null) => {
    const url = GET_INVESTOR_DOCUMENT_URL(investorId, type);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createInvestorWallet = (data, ctx = null) => {
    const url = CREATE_INVESTOR_WALLET_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const addWalletAmount = (data, ctx = null) => {
    const url = ADD_WALLET_AMOUNT_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getInvestorRelationshipManagerDetails = (id, ctx = null) => {
    const url = GET_INVESTOR_RELATIONSHIP_MANAGER_DETAILS(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorKycStatus = (investorId, ctx = null) => {
    const url = GET_INVESTOR_KYC_STATUS(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
export const getInvestorDemat = (id, ctx = null) => {
    const url = GET_INVESTOR_DEMAT_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getDepositoryParticipantsDemat = (id, ctx = null) => {
    const url = GET_DEPOSITORY_PARTICIPANTS_DEMAT_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createInvestorDemat = (data, ctx = null) => {
    const url = CREATE_INVESTOR_DEMAT();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const updateInvestorDemat = (data, ctx = null) => {
    const url = UPDATE_INVESTOR_DEMAT();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getInvestorEsignKycFilenames = (investorId, ctx = null) => {
    const url = GET_INVESTORS_ESIGN_KYC_FILENAMES_URL(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorEsignKyc = (investorId, type, ctx = null) => {
    const url = GET_INVESTORS_ESIGN_KYC_URL(investorId, type);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateInvestorExchangeAccount = (investorId, ctx = null) => {
    const url = UPDATE_INVESTOR_EXCHANGE_ACCOUNT(investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url);
    }
    return SsrHttpService.putWithAuth(url, ctx);
};

export const updateInvestorOverviewCompleted = (investorId, ctx = null) => {
    const url = UPDATE_INVESTOR_OVERVIEW_COMPLETED(investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url);
    }
    return SsrHttpService.putWithAuth(url, ctx);
};

export const updateMigratedInvestorBankDetails = (
    investorId,
    data,
    ctx = null
) => {
    const url = UPDATE_MIGRATED_INVESTOR_BANK_DETAILS(investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateMigratedInvestorDematDetails = (
    investorId,
    data,
    ctx = null
) => {
    const url = UPDATE_MIGRATED_INVESTOR_DEMAT_DETAILS(investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateVerifyInvestorDematByAdmin = (
    investorId,
    data,
    ctx = null
) => {
    const url = UPDATE_VERIFY_INVESTOR_DEMAT_BY_ADMIN(investorId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
}

export const downloadPendingInvestorDemats = () => {
    const url = DOWNLOAD_PENDING_INVESTOR_DEMATS_BY_ADMIN();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
}

export const bulkUploadVerifiedInvestorDemats = (entity, ctx = null) => {
    const url = BULK_UPLOAD_PENDING_INVESTOR_DEMATS_BY_ADMIN();
    const formData = new FormData();
    formData.append('file', entity.file);
    formData.append('fileName', entity.key);
    if (hasWindow()) {
        return CsrHttpService.postWithAuthMultipart(url, formData);
    }
    return SsrHttpService.postWithAuthMultipart(url, formData, ctx);
};

export const createInvestorDeclaration = (data, ctx = null) => {
    const url = CREATE_INVESTOR_DECLARATION();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getInvestorEsignForm = (investorId, ctx) => {
    const url = GET_INVESTOR_ESIGN_FORM_URL(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
}

export const getInvestorKycNudge = (investorId, ctx) => {
    const url = GET_INVESTOR_KYC_NUDGE(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const postInvestorPanHvOcr = (data) => {
    const url = POST_INVESTOR_PAN_HVOCR();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
}

export const createInvestorNominee = (payload) => {
    const url = INVESTOR_NOMINEE_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, payload);
    }
    return SsrHttpService.postWithAuth(url, payload, ctx);
}

export const getInvestorNominees = (investorId, ctx) => {
    const url = GET_INVESTOR_NOMINEE(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateInvestorNominee = (payload) => {
    const url = INVESTOR_NOMINEE_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, payload);
    }
    return SsrHttpService.putWithAuth(url, payload, ctx);
}



export const createInvestorPanValidation = (data, ctx = null) => {
    const url = CREATE_INVESTOR_PAN_VALIDATION();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getInvestorPanValidation = (data, ctx = null) => {
    const url = GET_INVESTOR_PAN_VALIDATION();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url, data);
    }
    return SsrHttpService.getWithAuth(url, data, ctx);
};

export const updateInvestorBankDetails = (id, data, ctx = null) => {
    const url = UPDATE_INVESTOR_INVITATION_BANK_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getAmoNudge = (id, ctx) => {
    const url = GET_INVESTOR_AMO_NUDGE(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
}

export const createBulkUploadSheet = (opportuntyId, data, ctx = null) => {
    const url = GET_BULK_OPPORTUNITY_PAYMENT_SCHEDULES_URL(opportuntyId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const downloadUpcomingPaymentScheduleByOpportunityId = (opportunityId, unitsPurchased, settlementType, ctx = null) => {
    const url = DOWNLOAD_PAYMENT_SCHEDULE_BY_OPPORTUNITY_ID(opportunityId, unitsPurchased, settlementType);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};