import {
    postWithOutAuthDecorator,
    getWithOutAuthDecorator,
    postWithAuthDecorator,
    postWithAuthDecoratorMultipart,
    getWithAuthDecorator,
    deleteWithAuthDecorator,
    putWithAuthDecorator,
    putWithOutAuthDecorator
} from '@services/http.service';

export const postWithOutAuth = async (url, entity) => {
    return postWithOutAuthDecorator(url, entity)
}

export const getWithOutAuth = async (url) => {
    return getWithOutAuthDecorator(url)
}

export const postWithAuth = async (url, entity) => {
    return postWithAuthDecorator(url, entity);
}

export const postWithAuthMultipart = async (url, entity) => {
    return postWithAuthDecoratorMultipart(url, entity);
};

export const getWithAuth = async (url) => {
    return getWithAuthDecorator(url);
}

export const deleteWithAuth = async (url, entity) => {
    return deleteWithAuthDecorator(url, entity);
}

export const putWithAuth = async (url, entity) => {
    return putWithAuthDecorator(url, entity);
}

export const putWithOutAuth = async (url, entity) => {
    return putWithOutAuthDecorator(url, entity)
}
