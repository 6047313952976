export const kycFlowGaEventsName = {
    SKIP_NOMINEE_IN_KYC: 'skipNomineeInKyc',
    ADD_NOMINEE_IN_KYC: 'addNomineeInKyc',
    PROCEED_ESIGN_KYC_DOCUMENT: "proceesEsignKycDocument",
    AADHAR_ESIGN_EVENT: "aadharEsignEvent",
    CAPTURE_DIGITAL_SIGNATURE: "captureDigitalSignature",
    GENERATE_KYC_DOCUMENT: "generateKycDocument",
    LAUNCH_HV_LIVE_PHOTO_SDK: "launchHyperVergeLivePhotoSdk",
    LIVE_PHOTO_VERIFIED: "livePhotoVerified",
    SUBMIT_BANK_DETAILS: "submitBankDetails",
    SUBMIT_DEMAT_ACCOUNT: "submitDematAccount",
    ACCEPT_BASIC_DETAILS: "acceptBasicDetails",
    ACCEPT_DIGI_LOCKER_CONSENT: "acceptDigiLockerConsent",
    GET_PAN_DETAILS_FROM_HYPERVERGE: "getPanDetailsFromHyperVerge",
    LAUNCH_HV_PAN_SDK: "launchHyperVergePanSdk",
    SUBMIT_PAN: "submitPan",
    SUBMIT_PERSONAL_DETAILS: "submitPersonalDetails",
    DIGI_LOCKER_VERIFIED_EVENT: "digiLockerVerifiedEvent",
    KYC_VERIFIED: "kycVerified",
    KYC_MANUAL_VERIFICATION: "kycManualVerification",
    HV_BANK_VERIFICATION: "hyperVergeBankVerification",
    LAUNCH_HV_BANK_SDK: "launchHyperVergeBankSdk",
    HV_BANK_RETRY: "hyperVergeBankRetry",
    EXIT_HV_BANK_VERIFICATION: "exitHyperVergeBankVerification",
    START_KYC_FROM_NUDGE: "startKycFromNudge",
    SKIP_KYC_FOR_NOW: "skipKycForNow"
}

export const profileGaEventsName = {
    ADD_ANOTHER_BANK_ACCOUNT: "addAnotherBankAccount",
    ADD_AND_UPDATE_NOMINEE_IN_PROFILE: 'addAndUpdateNomineeInProfile'
}

export const investmentFlowGaEventsName = {
    START_INVESTMENT: "startInvestment",
    DOWNLOAD_ORDER_SHEET_FROM_INVESTMENT_OVERVIEW: "downloadOrderSheetFromInvestmentOverview",
    CONTINUE_TO_PAYMENT_PAGE: "continueToPaymentpage",
    CONTINUE_TO_AMO_CONFIRMATION_PAGE: "continueToAmoConfirmationPage",
    PAY_LATER_FROM_FAILED_PAYMENT: "payLaterFromFailedPayment",
    RETRY_PAYMENT: "retryPayment",
    VIEWED_PAYMENT_SUCCESS: "viewedPaymentSuccess",
    VIEWED_PAYMENT_IN_PROGRESS: "viewedPaymentInProgress",
    VIEWED_PAYMENT_FAILED: "viewedPaymentFailed",
    CONTINUE_PAYMENT_WITH_NEFT_OR_RTGS: "continuePaymentWithNeftOrRtgs",
    CONTINUE_PAYMENT_WITH_NET_BANKING: "continuePaymentWithNetBanking",
    CONTINUE_PAYMENT_WITH_UPI: "continuePaymentWithUpi",
    VERIFY_UPI_PAYMENT_ID: "verifyUpiPaymentId",
    EXIT_PAYMENT_SELECTION_PAGE: "exitPaymentSelectionPage",
    CONTINUE_PAYMENT_LINK_FROM_PENDING_ORDERS: "continuePaymentLinkFromPendingOrders",
    VIEWED_AMO_CONFIRMATION: "viewedAmoConfirmation",
    PAYMENT_LINK_VIEWED_FROM_MAIL_OR_WHATSAPP: "paymentLinkViewedFromMailOrWhatsapp",
    VIEWED_ORDER_DETAILS_FROM_PAYMENT_SUCCESS: "viewedOrderDetailsFromPaymentSuccess",
    VIEWED_ORDER_DETAILS_FROM_PAYMENT_IN_PROGRESS: "viewedOrderDetailsFromPaymentInProgress",
    VIEWED_ORDER_CONFIRM: "viewedOrderConfirm",
    VIEWED_ORDER_FAILED: "viewedOrderFailed",
    CLICKED_TERMS_AND_CONDITIONS_INVESTMENT_OVERVIEW: "clickedTermsAndConditionsInvestmentOverview",
    CLICKED_AMO_CASHFREE_RETRY_PAYMENT: "clickedAmoCashfreeRetryPayment",
    CLICKED_AMO_CASHFREE_VIEW_ALL_ORDERS: "clickedAmoCashfreeViewAllOrders",
    CLICKED_AMO_OVERVIEW_VIEW_MORE_OPPORTUNITY: "clickedAmoOverviewViewMoreOpportunity",
    VIEW_AMO_CASHFREE_PAYMENT_IN_PROGRESS_PAYMENT_PAGE: "viewAmoCashFreePaymentInProgressPaymentPage",
    VIEW_AMO_CASHFREE_SUCCESS_PAYMENT_PAGE: "viewAmoCashFreeSuccessPaymentPage",
    VIEW_AMO_CASHFREE_FAILED_PAYMENT_PAGE: "viewAmoCashFreeFailedPaymentPage",
    CLICKED_AMO_CASHFREE_CONFIRM_PAYMENT: "clickedAmoCashFreeConfirmPayment",
    VIEWED_RBI_SDI_PAYMENT_CONFIRMATION: "viewed_rbi_sdi_payment_confirmation",
    DOWNLOADED_RBI_SDI_ORDER_SHEET: "downloaded_rbi_sdi_order_sheet",
    VIEWED_RBI_SDI_TNC_ON_PAYMENT_CONFIRMATION: "viewed_rbi_sdi_tnc_on_payment_confirmation",
    ACCEPTED_RBI_SDI_TNC_ON_PAYMENT_CONFIRMATION: "accepted_rbi_sdi_tnc_on_payment_confirmation",
    CONTINUE_RBI_SDI_TNC_ON_PAYMENT_CONFIRMATION: "continue_rbi_sdi_tnc_on_payment_confirmation",
    VIEWED_RBI_SDI_SUCCESSFUL_INVESTMENT_SCREEN: "viewed_rbi_sdi_successful_investment_screen",
    VIEWED_RBI_SDI_FAILED_INVESTMENT_SCREEN: "viewed_rbi_sdi_failed_investment_screen",
    VIEWED_RBI_SDI_PENDING_INVESTMENT_SCREEN: "viewed_rbi_sdi_pending_investment_screen",
    CLICKED_RBI_SDI_RETRY_PAYMENT: "clicked_rbi_sdi_retry_payment",
    VIEWED_PG_PAYMENT_METHOD_SELECTION_PAGE: "viewed_pg_payment_method_selection_page",
    CLICKED_PROCEED_TO_PAYMENT_GATEWAY: "clicked_proceed_to_payment_gateway"
}

export const loginSignupEventsName = {
    SIGNUP: "signupEvent",
    LOGIN: "loginEvent",
    SIGNUP_OTP: "verifyOtpSignup",
    LOGIN_OTP: "verifyOtplogin",
    CLICKED_LOGIN_SIGNUP_HEADER: "clicked_login_signup_header",
    SIGNUP_FORM_START: "signup_form_start"
}

export const triggerOnPage = {
    PENDING_ORDERS: "pending_orders_page",
    CASHFREE_AMO_PAYMENT_FAIL: "cashfree_amo_payment_fail_page",
    VISITED_SIGNUP_PAGE: "visitedSignupPage",
    VIEWED_NEWS_LETTERS_PAGE: "viewed_news_letters_page",
    CLICKED_NEWS_LETTERS_READ_NOW: "clicked_news_letters_read_now",
};

export const WelcomeQuestionnaireEventsName = {
    VIEWED_QUESTIONNAIRE: 'Viewed_Questionnaire_Page',
    CLICKED_QUESTIONNAIRE_CONTINUE: 'Click_Questionnaire_Continue',
    CLICKED_QUESTIONNAIRE_SKIP: 'Click_Questionnaire_Skip',
    VIEWED_WELCOME_PAGE: 'Viewed_Welcome_Page',
    CLICKED_BOOK_CONSULTATION_WELCOME_PAGE:
        'clicked_book_consultation_welcomepage',
    CLICKED_START_KYC_WELCOME_PAGE: 'clicked_startKYC_welcomepage',
    CLICKED_SKIP_WELCOME_PAGE: 'clicked_skip_welcomepage',
    VIEWED_WELCOME_BACK_PAGE: 'viewed_welcomeback',
    CLICKED_BOOK_CONSULTATION_WELCOME_BACK_PAGE:
        'clicked_book_consultation_welcomeback',
    CLICKED_START_KYC_WELCOME_BACK_PAGE: 'clicked_startKYC_welcomeback',
    CLICKED_COMPLETE_KYC_WELCOME_BACK_PAGE: 'clicked_completeKYC_welcomeback',
    CLICKED_SKIP_WELCOME_BACK_PAGE: 'clicked_skip_welcomeback',
};

export const dashboardGaEventsName = {
    CLICKED_MY_REFERRAL_SIDEBAR: "clickedMyReferralSidebar",
    CLICKED_COPY_MY_REFERRAL_RNE: 'clickedCopyMyReferralRne',
    CLICKED_INVEST_NOW_MY_REFERRAL_PAGE: 'clickedInvestNowMyReferralPage',
    CLICKED_CONTACT_SUPPORT_MY_REFERRAL_PAGE: 'clickedContactSupportMyReferralPage',
    CLICKED_COMPLETE_KYC_MY_REFERRAL_PAGE: 'clickedCompleteKycMyReferralPage'
}

export const footerGaEventsName = {
    REFER_AND_EARN_FOOTER: "referAndEarnFooter",
    NEWS_LETTERS_FOOTER: "newsLettersFooter"
}

export const headerGaEventsName = {
    REFER_AND_EARN_LOGIN: 'referAndEarnLogin',
    REFER_AND_EARN_LOGOUT: 'referAndEarnLogout',
    BOND_DIRECTORY_HEADER: 'clicked_bond_directory_navigation_header',
    NEWS_LETTERS_HEADER: 'clicked_news_letters_header'
};

export const referAndEarnGaEventsName = {
    CLICKED_COPY_REFERRAL_LINK: "clickedCopyReferralLinkRne",
    CLICKED_SIGNUP_TO_EARN_REWARD: 'clickedSignupToEarnreward'
}

export const navigationGaEventsName = {
    CLICKED_CHAT_WITH_RM_MOBILE_WEB_NAVIGATION: 'clickedChatWithRmMobileWebNavigation',
    CLICKED_CHAT_WITH_RM_SIDENAV_MOBILE_WEB_NAVIGATION: 'clickedChatWithRmSideNavMobileWebNavigation',
    CLICKED_INVESTOR_INITIAL_MOBILE_WEB_NAVIGATION: 'clickedInvestorInitialMobileWebNavigation',
    CLICKED_HOME_PAGE_MORE_MOBILE_WEB_NAVIGATION: "clickedHomePageMoreMobileWebNavigation",
    CLICKED_RM_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedRelationshipManagerSideNavMobileWebNavigation",
    CLICKED_REFER_AND_EARN_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedReferandEarnSideNavMobileWebNavigation",
    CLICKED_ABOUT_US_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedAboutUsSideNavMobileWebNavigation",
    CLICKED_CONTACT_US_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedContactUsSideNavMobileWebNavigation",
    CLICKED_FAQS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedFaqsSideNavMobileWebNavigation",
    CLICKED_BLOGS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedBlogsSideNavMobileWebNavigation",
    CLICKED_CREDIT_EVALUATION_PROCESS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedCreditEvaluationSideNavMobileWebNavigation",
    CLICKED_BOND_FUNDAMENTALS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedBondFundamentalsSideNavMobileWebNavigation",
    CLICKED_MEDIA_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedMediaSideNavMobileWebNavigation",
    CLICKED_GLOSSARY_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedGlossarySideNavMobileWebNavigation",
    CLICKED_SOVEREIGN_GOLD_BONDS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedSovereignGoldBondsSideNavMobileWebNavigation",
    CLICKED_T_BILLS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedTBillsSideNavMobileWebNavigation",
    CLICKED_G_SEC_AND_SDL_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedGSecAndSdlSideNavMobileWebNavigation",
    CLICKED_CD_LISTED_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedListedCorporateDeptSideNavMobileWebNavigation",
    CLICKED_SDIS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedSDIsSideNavMobileWebNavigation",
    CLICKED_PRODUCTS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedProductsSideNavMobileWebNavigation",
    CLICKED_KNOWLEDGE_CENTER_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedKnowledgeCenterSideNavMobileWebNavigation",
    CLICKED_OUR_COMPANY_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedOurCompanySideNavMobileWebNavigation",
    CLICKED_VALUES_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedValuesSideNavMobileWebNavigation",
    CLICKED_INVESTORS_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedInvestorsSideNavMobileWebNavigation",
    CLICKED_LOGOUT_SIDENAV_MOBILE_WEB_NAVIGATION: "clickedLogoutSideNavMobileWebNavigation",
    CLICKED_NEWS_LETTERS_SIDENAV_MOBILE_WEB_NAVIGATION: "clicked_news_letters_sidenav_mobile_web_navigation",
}

export const homepageGaEventName = {
    CLICKED_INVEST_NOW_DEAL_HOME_PAGE: "clickedInvestNowDealHomePage",
    CLICKED_VIEW_OPPORTUNITY_DEAL_HOME_PAGE: 'clickedViewOpportunityDealHomePage',
    CLICKED_INVEST_NOW_DEAL_SIGNUP_PAGE: "clickedInvestNowDealSignupPage",
    CLICKED_VIEW_OPPORTUNITY_DEAL_SIGNUP_PAGE: 'clickedViewOpportunityDealSignupPage'
}

export const opportunitiesGaEventsName = {
    CLICKED_VIEW_BONDS_TAB_ACTIVE: "clicked_view_bonds_tab_active",
    CLICKED_VIEW_SDI_TAB_ACTIVE: "clicked_view_sdi_tab_active",
    CLICKED_VIEW_TBILLS_TAB_ACTIVE: "clicked_view_tbills_tab_active",
    CLICKED_VIEW_BONDS_TAB_RECENTLY_CLOSED: "clicked_view_bonds_tab_recently_closed",
    CLICKED_VIEW_SDI_TAB_RECENTLY_CLOSED: "clicked_view_sdi_tab_recently_closed",
    CLICKED_VIEW_TBILLS_TAB_RECENTLY_CLOSED: "clicked_view_tbills_tab_recently_closed",
    VIEWED_RBI_SDI_POPUP: "viewed_rbi_sdi_popup",
    CLICKED_RBI_SDI_TAB_ACTIVE: "clicked_rbi_sdi_tab_active",
    CLICKED_SEBI_SDI_TAB_ACTIVE: "clicked_sebi_sdi_tab_active",
    CLICKED_RBI_SDI_TAB_RECENTLY_CLOSED: "clicked_rbi_sdi_tab_recently_closed",
    CLICKED_SEBI_SDI_TAB_RECENTLY_CLOSED: "clicked_sebi_sdi_tab_recently_closed",
    VIEWED_RBI_SDI_OPPORTUNITY_DETAILS: "viewed_rbi_sdi_opportunity_details",
    VIEWED_RBI_SDI_CLOSED_OPPORTUNITY_DETAILS: "viewed_rbi_sdi_closed_opportunity_details"

}
export const bondDirectoryGaEventsName = {
    CLICKED_BOND_DIRECTORY_HOME_PAGE: 'bond_directory_homepage_click',
    INITIATED_BOND_DIRECTORY_SEARCH: 'bond_directory_search_initiated',
    CLICKED_BOND_DIRECTORY_SEARCH_RESULT: 'bond_directory_search_result_click',
    VIEWED_BOND_DETAILS_FROM_LANDING_PAGE: 'bond_details_landing',
    VIEWED_BOND_DETAILS_PAGE: 'bond_details_viewed',
    CLICKED_BOND_DIRECTORY_CTA_SHOW_INTEREST:
        'bond_directory_page_CTA_showinterest',
    CLICKED_BOND_DIRECTORY_FOOTER: 'clicked_bond_directory_footer',
};
