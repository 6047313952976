import { theme as tailwindTheme } from 'tailwind.config';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { cssTransition, toast } from 'react-toastify';
import Text from '../Text';

export const getCustomStyles = (
    primaryColor = tailwindTheme?.colors?.primary?.[500],
    optionBgColor = tailwindTheme?.colors?.gray?.[100],
    height = '40px',
    disabledOpacity = 0.5,
    controlStyles = {
        padding: 0,
        borderRadius: 8,
        '&:focus': {
            borderColor: '#FFA400',
            outline: 'none',
        },
    },
    bgDisabled = tailwindTheme?.colors?.gray?.[100]
) => {
    return {
        container: (provided, state) => {
            return {
                ...provided,
                flexGrow: 1,
                '&:focus': {
                    borderColor: primaryColor,
                    borderWidth: '2px',
                },
            };
        },
        valueContainer: (base) => {
            return {
                ...base,
                display: 'flex',
                fontSize: '14px',
            };
        },
        option: (provided, state) => {
            return {
                ...provided,
                cursor: 'pointer',
                backgroundColor: state.isSelected ? optionBgColor : 'white',
                color: state.isSelected ? primaryColor : 'black',
            };
        },
        control: (provided, state) => {
            return {
                ...provided,
                ...controlStyles,
                backgroundColor: state.isDisabled
                    ? bgDisabled
                    : provided.backgroundColor,
                borderColor: state.isFocused
                    ? primaryColor
                    : tailwindTheme?.colors?.gray?.[300],
                borderWidth: state.isFocused ? '2px' : '1px',
                cursor: 'pointer',
                height: height ? height : '40px',
                '&:hover': {
                    borderWidth: '2px',
                },
                '&:focus': {
                    borderColor: tailwindTheme?.colors?.green?.[500],
                    borderWidth: '2px',
                },
            };
        },
        input: (provided, state) => {
            return {
                ...provided,
            };
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? disabledOpacity : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        },
        dropdownIndicator: (base, { selectProps: { menuIsOpen } }) => ({
            ...base,
            transition: 'all .3s ease',
            transform: menuIsOpen
                ? 'rotate(180deg)'
                : null,
        }),
    };
};

export const copyToClipboard = (code, setCopySuccess) => {
    const bounce = cssTransition({
        enter: 'animate__animated animate__bounceIn',
        exit: 'animate__animated animate__bounceOut',
    });
    navigator.clipboard
        .writeText(code)
        .then(() => {
            setCopySuccess(code);
            toast.dismiss();
            toast.success(
                <Text
                    content={'Copied to clipboard!'}
                    className="p4-medium text-basicWhite"
                />,

                {
                    position: 'top-center',
                    progress: null,
                    closeButton: false,
                    hideProgressBar: true,
                    transition: bounce,
                    style: {
                        '--toastify-color-progress-info':
                            tailwindTheme?.colors?.semantic['success-base'], // Replace with your desired progress bar color
                        '--toastify-icon-color-info':
                            tailwindTheme?.colors?.semantic['success-base'],
                        '--toastify-toast-background':
                            tailwindTheme?.colors?.basicBlack + 89,
                        '--toastify-color-light':
                            tailwindTheme?.colors?.basicBlack + 89,
                        '--toastify-toast-min-height': '40px',
                    },
                    autoClose: 3000,
                }
            );
            setTimeout(() => {
                setCopySuccess(null);
            }, 4000);
        })
        .catch((error) => console.error('Failed to copy to clipboard:', error));
};

export const animations = {
    popIn: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: [1, 1, 1, 1, 1],
            scale: [0, 0.25, 0.5, 0.75, 1, 1.01, 1],
            transition: {
                duration: 0.3,
                type: 'spring',
                damping: 500,
                stiffness: 700,
            },
        },
        exit: {
            opacity: 0,
            scale: [1, 0.5, 0],
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 50,
                stiffness: 700,
            },
        },
    },
    dropIn: {
        hidden: {
            x: '-100vw',
            opacity: 0,
        },
        visible: {
            x: '0',
            opacity: 1,
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 50,
                stiffness: 700,
            },
        },
        exit: {
            x: '-100vw',
            opacity: 0,
            transition: {
                duration: 0.3,
                type: 'spring',
                damping: 50,
                stiffness: 700,
            },
        },
    },
    dropInY: {
        hidden: {
            y: '-100vh',
            opacity: 0,
        },
        visible: {
            y: '0',
            opacity: 1,
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 50,
                stiffness: 700,
            },
        },
        exit: {
            y: '200vh',
            opacity: 0,
            transition: {
                duration: 0.1,
                type: 'spring',
                damping: 50,
                stiffness: 1000,
            },
        },
    },
    newspaper: {
        hidden: {
            transform: 'scale(0) rotate(720deg)',
            opacity: 0,
            transition: {
                delay: 0.3,
            },
        },
        visible: {
            transform: ' scale(1) rotate(0deg)',
            opacity: 1,
            transition: {
                duration: 0.5,
            },
        },
        exit: {
            transform: 'scale(0) rotate(-720deg)',
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
    },
    flip: {
        hidden: {
            transform: 'scale(0) rotateX(-360deg)',
            opacity: 0,
            transition: {
                delay: 0.3,
            },
        },
        visible: {
            transform: ' scale(1) rotateX(0deg)',
            opacity: 1,
            transition: {
                duration: 0.5,
            },
        },
        exit: {
            transform: 'scale(0) rotateX(360deg)',
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
    },
    opacity: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.1,
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.1,
            },
        },
    },
};

export const getPaginationItems = (currentPage, pageCount) => {
    if (pageCount <= 5) {
        return Array.from({ length: pageCount }, (_, i) => i + 1);
    } else {
        const startPages = currentPage > 3 ? [1, '...1'] : [1, 2, 3, 4];
        const endPages =
            currentPage < pageCount - 2
                ? ['...2', pageCount]
                : [pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
        if (currentPage > 3 && currentPage < pageCount - 2) {
            return [
                ...startPages,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                ...endPages,
            ];
        } else {
            return [...startPages, ...endPages];
        }
    }
};
 
