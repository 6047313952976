import * as React from 'react';
import { cn } from '../utils/utils';
import { cva } from 'class-variance-authority';
import Image from '../Image';
import Text from '../Text';
import Button from './Button';

/**
 * Defines icon variants using class-variance-authority for consistent styling across different icon sizes and positions.
 */
const iconVariants = cva('', {
    variants: {
        size: {
            medium: 'w-8 h-8', // Medium size icon
            large: 'w-10 h-10', // Large size icon
        },
        position: {
            left: 'mr-2', // Icon positioned to the left
            top: 'mb-4 flex justify-center items-center bg-basicWhite shadow-sm rounded-full w-12 h-12', // Icon positioned at the top
        },
    },
    defaultVariants: {
        size: 'medium', // Default icon size
    },
});

/**
 * Defines text variants for different types of text within the popup, ensuring consistent styling.
 */
const popupTextVariants = cva('text-center', {
    variants: {
        type: {
            heading: 'p3-semibold md:p2-semibold text-primary-500', // Styling for heading text
            subHeading: 'p4-regular text-gray-600', // Styling for subheading text
        },
    },
    defaultVariants: {
        type: 'heading', // Default text type
    },
});

/**
 * Popup component to display a modal dialog.
 * Utilizes React's forwardRef to allow ref forwarding.
 */
const Popup = React.forwardRef(
    ({ isOpen, onClose, className, ...props }, ref) => {
        // Effect to prevent body scroll when popup is open
        React.useEffect(() => {
            document.body.style.overflow = isOpen ? 'hidden' : 'unset';
            return () => {
                document.body.style.overflow = 'unset';
            };
        }, [isOpen]);

        // Do not render the component if it is not open
        if (!isOpen) return null;

        return (
            <div className="fixed inset-0 z-50 overflow-y-auto flex-center">
                <div
                    className="absolute inset-0 bg-opacity-50 bg-basicBlack backdrop-blur-sm"
                    onClick={onClose} // Close popup when overlay is clicked
                />
                <div
                    ref={ref}
                    className={cn(
                        'relative flex flex-col gap-4 m-auto py-5 px-4 md:p-6 w-[328px] md:w-[512px] bg-basicWhite rounded-2xl shadow-lg focus:outline-none',
                        className
                    )}
                    tabIndex={-1}
                    {...props}
                />
            </div>
        );
    }
);
Popup.displayName = 'Popup';

/**
 * PopupHeader component to display the header of the popup, including an optional icon, title, and description.
 */
const PopupHeader = React.forwardRef(
    (
        {
            iconPosition,
            iconSize,
            iconType,
            title,
            description,
            className,
            ...props
        },
        ref
    ) => (
        <div
            ref={ref}
            className={cn(
                'flex flex-col justify-between items-center gap-1',
                className
            )}
            {...props}
        >
            <div
                className={cn('flex flex-col items-center', {
                    'flex-row': iconPosition === 'left', // Adjust layout based on icon position
                })}
            >
                <PopupHeaderIcon
                    position={iconPosition}
                    type={iconType}
                    size={iconSize}
                />
                <PopupText type="heading">{title}</PopupText>
            </div>
            <PopupText type="subHeading">{description}</PopupText>
        </div>
    )
);
PopupHeader.displayName = 'PopupHeader';

/**
 * PopupHeaderIcon component to display the icon in the header of the popup.
 * Supports dynamic icon types, sizes, and positions.
 */
const PopupHeaderIcon = React.forwardRef(
    (
        {
            size = 'medium',
            type = 'success',
            position = 'top',
            className,
            iconPath,
            iconClassName,
            ...props
        },
        ref
    ) => {
        // Function to determine the icon based on the type or a custom path
        const getIcon = () => {
            if (iconPath) return iconPath; // Use custom icon path if provided
            switch (type) {
                case 'success':
                    return '/images/SuccessFill.svg';
                case 'error':
                    return '/images/ErrorFill.svg';
                case 'warning':
                    return '/images/WarningFill.svg';
                default:
                    return null; // Return null if no matching type
            }
        };

        const icon = getIcon();

        // Do not render if no icon is determined
        if (!icon) return null;

        return (
            <div
                ref={ref}
                className={cn(iconVariants({ position }), className)}
                {...props}
            >
                <Image
                    src={icon}
                    className={cn(iconVariants({ size }), iconClassName)}
                />
            </div>
        );
    }
);
PopupHeaderIcon.displayName = 'PopupHeaderIcon';

/**
 * PopupText component to display text within the popup.
 * Supports different types for consistent styling.
 */
const PopupText = React.forwardRef(({ type, className, icon, ...props }, ref) =>
    icon ? (
        <div className="flex items-center">
            <PopupHeaderIcon type={icon} position="left" />
            <h3
                ref={ref}
                className={cn(popupTextVariants({ type }), className)}
                {...props}
            />
        </div>
    ) : (
        <h3
            ref={ref}
            className={cn(popupTextVariants({ type }), className)}
            {...props}
        />
    )
);
PopupText.displayName = 'PopupText';

/**
 * PopupHelperText component to display helper text with an optional link.
 * Utilizes the PopupText component for consistent styling.
 */
const PopupHelperText = React.forwardRef(
    ({ text, highlightedText, href = '', className, ...props }, ref) => (
        <Text
            content={
                <>
                    {text}{' '}
                    {highlightedText && (
                        <a
                            href={href}
                            className={cn('p4-underline text-primary-500')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {highlightedText}
                        </a>
                    )}
                </>
            }
            className={cn(popupTextVariants({ type: 'subHeading' }), className)}
        />
    )
);
PopupHelperText.displayName = 'PopupHelperText';

/**
 * PopupBody component to display the main content of the popup.
 * Provides a container for any children passed to it.
 */
const PopupBody = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn('flex flex-col gap-4', className)}
        {...props}
    />
));
PopupBody.displayName = 'PopupBody';

/**
 * PopupButtonGroup component to display a group of buttons in the popup.
 * Supports primary and secondary buttons with customizable actions.
 */
const PopupButtonGroup = React.forwardRef(
    (
        {
            primaryOnClick,
            primaryButtonText,
            secondaryOnClick,
            secondaryButtonText,
            buttonStyle,
            className,
            ...props
        },
        ref
    ) => {
        // Function to determine button size based on the window width
        const getButtonSize = () =>
            window.innerWidth < 768 ? 'small' : 'medium';

        return (
            <div
                ref={ref}
                className={cn(
                    'flex-center md:gap-4 gap-3',
                    { 'flex-row-reverse': buttonStyle === 'alternate' }, // Adjust layout based on button style
                    className
                )}
                {...props}
            >
                <Button
                    onClick={primaryOnClick}
                    buttonType="primary"
                    buttonSize={getButtonSize()}
                    buttonText={primaryButtonText}
                />
                {secondaryButtonText && (
                    <Button
                        onClick={secondaryOnClick}
                        buttonType="secondary"
                        buttonSize={getButtonSize()}
                        buttonText={secondaryButtonText}
                    />
                )}
            </div>
        );
    }
);
PopupButtonGroup.displayName = 'PopupButtonGroup';

/**
 * PopupFooter component to display the footer content of the popup.
 * Includes helper text with a link and a button group for actions.
 */
const PopupFooter = React.forwardRef(
    (
        {
            helperText,
            highlightText,
            href,
            primaryButtonText,
            primaryOnClick,
            secondaryButtonText,
            secondaryOnClick,
            buttonStyle,
            className,
            ...props
        },
        ref
    ) => (
        <div
            ref={ref}
            className={cn(
                'flex flex-col justify-end items-center gap-6',
                className
            )}
            {...props}
        >
            {helperText && (
                <PopupHelperText
                    text={helperText}
                    highlightedText={highlightText}
                    href={href}
                />
            )}
            <PopupButtonGroup
                primaryButtonText={primaryButtonText}
                primaryOnClick={primaryOnClick}
                secondaryButtonText={secondaryButtonText}
                secondaryOnClick={secondaryOnClick}
                buttonStyle={buttonStyle}
            />
        </div>
    )
);
PopupFooter.displayName = 'PopupFooter';

export {
    Popup,
    PopupHeader,
    PopupHeaderIcon,
    PopupText,
    PopupBody,
    PopupFooter,
    PopupButtonGroup,
};
