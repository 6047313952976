import {UrlParamsReplace} from './index';


export const GET_ISIN_DETAIL_URL = (isin, isForceFetch) =>
    UrlParamsReplace('/admin/bondDetails/isin/:isin?force=:isForceFetch', {
        isin,
        isForceFetch,
    });

export const GET_NSE_ELIGIBLE_BANKS = () =>
    UrlParamsReplace('/nse-eligible-banks');
