import { OpportunityTypeDisplayNamesPieChart } from "@helpers/enums/opportunity-enums";
import { headerGaEventsName, navigationGaEventsName } from "@helpers/ga-events-name-enum";
import OpportunityTypes from '@helpers/opportunity-types';
import {
    CB_PRODUCT_ROUTE,
    GSEC_SDL_PRODUCT_ROUTE,
    SDIs_PRODUCT_ROUTE,
    SGB_PRODUCT_ROUTE,
    TB_PRODUCT_ROUTE,
} from '@ui/lib/constants/routes';
import { theme } from "tailwind.config";

export const sideBarNavItems = [
    {
        id: 0,
        name: 'Dashboard',
        iconLight: 'dashboard-menu-icon-light.svg',
        iconDark: 'dashboard-menu-icon-dark.svg',
        path: '',
        show: true,
        gtmtag: 'gtm-click-dashboard-in-sidebar'
    },
    {
        id: 1,
        name: 'My Investments',
        iconLight: 'investment-menu-icon-light.svg',
        iconDark: 'investment-menu-icon-dark.svg',
        path: 'my-investments',
        show: true,
        gtmtag: 'gtm-click-my-investment-in-sidebar'
    },
    {
        id: 2,
        name: 'Pending Orders',
        iconLight: 'Time-clock-light.svg',
        iconDark: 'Time-clock-dark.svg',
        path: 'pending-orders',
        show: true,
        gtmtag: 'gtm-click-pending-order-in-sidebar'
    },
    // {
    //     id: 2,
    //     name: 'JiraafSafe',
    //     iconLight: 'wallet-icon-light.svg',
    //     iconDark: 'wallet-icon-dark.svg',
    //     path: 'jiraaf-safe',
    //     show: false,
    // },
    // {
    //     id: 3,
    //     name: 'My Referrals',
    //     iconLight: 'my-referral-menu-icon-light.svg',
    //     iconDark: 'my-referral-menu-icon-dark.svg',
    //     path: 'my-referrals',
    //     show: false,
    //     gtmCustomTrigger: dashboardGaEventsName.CLICKED_MY_REFERRAL_SIDEBAR
    // },
    // {
    //     id: 4,
    //     name: 'Preferences',
    //     iconLight: 'wallet-pref-icon-light.svg',
    //     iconDark: 'wallet-pref-icon-dark.svg',
    //     path: 'jiraaf-safe/preference',
    //     show: false,
    // },
    {
        id: 5,
        name: 'Income Certificate',
        iconLight: 'paper-light.svg',
        iconDark: 'paper-dark.svg',
        path: 'income-certificate',
        show: false,
    },
];

export const menuItems = [
    {
        name: 'Bond Directory',
        link: '/bond-directory',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: false,
        selectOn: ['/bond-directory', '/bond-directory/isin/[isin]'],
        show: false,
        icon: '/images/header/files.svg',
        gTagMobileLoggedIn: headerGaEventsName.BOND_DIRECTORY_HEADER,
        gtmCustomTrigger: headerGaEventsName.BOND_DIRECTORY_HEADER,
    },
    {
        name: 'Products',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        selectOn: ['/products'],
        icon: '/images/header/products-icon.svg',
        submenu: [
            {
                name: 'Listed Corporate Bonds',
                link: CB_PRODUCT_ROUTE,
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-listed-corporate-bonds',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_CD_LISTED_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Sovereign Gold Bonds',
                link: SGB_PRODUCT_ROUTE,
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-listed-sovereign-gold-bond',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_SOVEREIGN_GOLD_BONDS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Treasury Bills',
                link: TB_PRODUCT_ROUTE,
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-treasury-bills',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_T_BILLS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'G-Sec and SDL',
                link: GSEC_SDL_PRODUCT_ROUTE,
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-g-sec-and-sdl',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_G_SEC_AND_SDL_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Securitized Debt Instruments',
                link: SDIs_PRODUCT_ROUTE,
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-sdis',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_SDIS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
        ],
        show: true,
        gtmtag: 'gtm-click-product-page-btn',
        gTagMobileLoggedIn: navigationGaEventsName.CLICKED_PRODUCTS_SIDENAV_MOBILE_WEB_NAVIGATION,
    },
    {
        name: 'Knowledge Centre',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        icon: '/images/header/knowledge-center-icon.svg',
        selectOn: [
            '/blogs',
            '/media',
            '/faq',
            // '/webinars',
            '/blogs/[slug]',
            '/credit-evaluation-process',
            '/glossary',
            '/bondfundamentals',
        ],
        submenu: [
            {
                name: 'Bond Fundamentals',
                link: '/bondfundamentals',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-bond-fundamentals',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_BOND_FUNDAMENTALS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Credit Assessment Process',
                link: '/credit-evaluation-process',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-credit-assessment-process',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_CREDIT_EVALUATION_PROCESS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Blogs',
                link: '/blogs',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-blogs-page-link',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_BLOGS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Media',
                link: '/media',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-media-page-link',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_MEDIA_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Newsletter',
                link: '/news-letters',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_NEWS_LETTERS_SIDENAV_MOBILE_WEB_NAVIGATION,
                gtmCustomTrigger: headerGaEventsName.NEWS_LETTERS_HEADER,
            },
            {
                name: 'FAQs',
                link: '/faq',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-faqs-page-link',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_FAQS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            // {
            //     name: 'Webinars',
            //     link: '/webinars',
            //     showWhenLoggedIn: true,
            // },
            {
                name: 'Glossary',
                link: '/glossary',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-glossary-page-link',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_GLOSSARY_SIDENAV_MOBILE_WEB_NAVIGATION
            },
        ],
        show: true,
        gtmtag: 'gtm-click-knowledge-centre',
        gTagMobileLoggedIn: navigationGaEventsName.CLICKED_KNOWLEDGE_CENTER_SIDENAV_MOBILE_WEB_NAVIGATION,
    },
    {
        name: 'About Us',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        icon: '/images/header/about-us-icon.svg',
        selectOn: ['/about-us', '/team'],
        submenu: [
            {
                name: 'Our Company',
                link: '/about-us#',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-our-company-page-link',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_OUR_COMPANY_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Values',
                link: '/about-us#values',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-values-page',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_VALUES_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Investors',
                link: '/about-us#investors',
                showWhenLoggedIn: true,
                gtmtag: 'gtm-click-investors-section',
                gTagMobileLoggedIn: navigationGaEventsName.CLICKED_INVESTORS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            // {
            //     name: 'The Team',
            //     link: '/team',
            //     showWhenLoggedIn: true,
            //    gtmtag: 'gtm-click-teams-page-link'
            // },
            // {
            //     name: 'Careers',
            //     link: '/careers',
            //     showWhenLoggedIn: false,
            //    gtmtag: 'gtm-click-careers-page-link'
            // },
        ],
        show: true,
        gtmtag: 'gtm-click-about-us-page-link',
        gTagMobileLoggedIn: navigationGaEventsName.CLICKED_ABOUT_US_SIDENAV_MOBILE_WEB_NAVIGATION,
    },
    {
        name: 'Contact Us',
        showWhenLoggedIn: false,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        link: '/contact-us',
        selectOn: ['/contact-us'],
        show: true,
        gtmtag: 'gtm-click-contact-us',
        gTagMobileLoggedIn: navigationGaEventsName.CLICKED_CONTACT_US_SIDENAV_MOBILE_WEB_NAVIGATION
    },
    {
        name: 'Become a Partner',
        showWhenLoggedIn: false,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        openInNewTab: true,
        link: `${process.env.NEXT_PUBLIC_APP_PARTNER_URL}`,
        selectOn: [`${process.env.NEXT_PUBLIC_APP_PARTNER_URL}`],
        newTab: true,
        show: false,
    },
    {
        name: 'Opportunities',
        link: '/opportunities',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: false,
        showOnHomePageOnly: false,
        selectOn: ['/opportunities'],
        show: true,
        gtmtag: 'gtm-click-opportunities-in-header'
    },
    {
        name: 'Dashboard',
        link: '/dashboard',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: false,
        showOnHomePageOnly: false,
        selectOn: [
            '/dashboard',
            '/dashboard/my-investments',
            '/dashboard/my-referrals',
            '/dashboard/jiraaf-safe/preference',
            '/dashboard/my-investments/[investmentId]',
            '/dashboard/income-certificate',
        ],
        show: true,
        gtmtag: 'gtm-click-dashboard-in-header'
    },
    // {
    //     name: 'JiraafSafe',
    //     link: '/dashboard/jiraaf-safe',
    //     showWhenLoggedIn: true,
    //     showWhenNotLoggedIn: false,
    //     showOnHomePageOnly: false,
    //     selectOn: ['/dashboard/jiraaf-safe'],
    //     isNew: true,
    //     show: false,
    // },
    // {
    //     name: 'Refer & Earn',
    //     link: '/refer-and-earn',
    //     showWhenLoggedIn: true,
    //     showWhenNotLoggedIn: false,
    //     showOnHomePageOnly: false,
    //     selectOn: ['/refer-and-earn'],
    //     show: false,
    //     gtmCustomTrigger: headerGaEventsName.REFER_AND_EARN_LOGIN,
    //     gTagMobileLoggedIn: navigationGaEventsName.CLICKED_REFER_AND_EARN_SIDENAV_MOBILE_WEB_NAVIGATION,
    //     suffixIcon: () => <Image
    //         src="/images/header/new-tag.svg"
    //         className="h-4 w-6"
    //     />
    // },
    // {
    //     name: 'Refer & Earn',
    //     link: '/referral-program',
    //     showWhenLoggedIn: false,
    //     showWhenNotLoggedIn: true,
    //     showOnHomePageOnly: false,
    //     selectOn: ['/referral-program'],
    //     show: false,
    //     gtmCustomTrigger: headerGaEventsName.REFER_AND_EARN_LOGOUT,
    //     suffixIcon: () => <Image
    //         src="/images/header/new-tag.svg"
    //         className="h-4 w-6"
    //     />
    // },
];

export const getAssetClassValue = (name, aggregationByAssetClass) => {
    const value = aggregationByAssetClass
        ?.filter((each) => {
            if (each.assetClass === name) {
                return each.total;
            }
            return 0;
        })
        .map((each) => each.total);
    if (value) {
        return value[0];
    }
    return;
};

export const getPieChartData = (aggregationByAssetClass) => {
    const pieChartData = aggregationByAssetClass?.map((each) => ({
        name: each.assetClass,
        label: each.assetClass ? OpportunityTypeDisplayNamesPieChart[each.assetClass] : '-',
        value: each.total,
        color: getAssetClassColor(each.assetClass),
    }));

    return pieChartData;
};

export const getAssetClassColor = (className) => {
    switch (className) {
        case OpportunityTypes.CORPORATE_BOND:
            return theme.colors.secondary[300];
        case OpportunityTypes.SDL:
            return theme.colors.purple[500];
        case OpportunityTypes.SOVEREIGN_GOLD_BONDS:
            return theme.colors.green[500];
        case OpportunityTypes.SECURITISED_DEBT_INSTRUMENT:
            return theme.colors.tiffanyBlue[100];
        case OpportunityTypes.G_SEC:
            return theme.colors.periwinkle[100];
        case OpportunityTypes.TREASURY_BILL:
            return theme.colors.pink[400];
        case OpportunityTypes.UNLISTED_SECURITISED_DEBT_INSTRUMENT:
            return theme.colors.uranianBlue[100];
    }
};

export function camelToLabel(camelStr) {
    return camelStr
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
}

export function labelToCamel(labelStr) {
    return labelStr
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())
        .replace(/\s+/g, '');
}



