import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const WelcomeLayout = dynamic(() => import('./WelcomeLayout'));
const InvestmentLayout = dynamic(() => import('./InvestmentLayout'));
const AdminV2Layout = dynamic(() => import('./AdminV2Layout'));
const EmptyLayout = dynamic(() => import('./EmptyLayout'));
const AdminLayout = dynamic(() => import('./Layout').then((mod) => mod.Layout));
const InvestorLayout = dynamic(() => import('./InvestorLayout'));
const KycLayout = dynamic(() => import('./KycLayout'));
const DashboardLayout = dynamic(() => import('./DashboardLayout'));
const StaticLayout = dynamic(() => import('./StaticLayout'));
const NormalLayout = dynamic(() => import('./NormalLayout'));
const ProductPageLayout = dynamic(() => import('./ProductPageLayout'));

const LayoutConfig = [
    {
        path: '/adminLogin',
        Layout: AdminV2Layout,
    },
    {
        path: '/admin',
        Layout: AdminLayout,
    },
    {
        path: '/opportunities',
        Layout: DashboardLayout,
    },
    {
        path: '/add-beneficiary',
        Layout: DashboardLayout,
    },
    {
        path: '/freecharge',
        Layout: EmptyLayout,
    },
    {
        path: '/opportunity/[id]/',
        Layout: InvestmentLayout,
    },
    {
        path: '/opportunity',
        Layout: InvestorLayout,
    },
    {
        path: '/kyc/verification',
        Layout: KycLayout,
    },
    {
        path: '/kyc',
        Layout: DashboardLayout,
    },
    {
        path: '/dashboard',
        Layout: DashboardLayout,
    },
    {
        path: '/thankyou',
        Layout: InvestorLayout,
    },
    {
        path: '/response',
        Layout: InvestorLayout,
    },
    {
        path: '/Emailverify',
        Layout: InvestorLayout,
    },
    {
        path: '/profile',
        Layout: InvestorLayout,
    },
    {
        path: '/home',
        Layout: StaticLayout,
    },
    {
        path: '/faq',
        Layout: StaticLayout,
    },
    {
        path: '/jiraaf-safe',
        Layout: StaticLayout,
    },
    {
        path: '/contact-us',
        Layout: StaticLayout,
    },
    {
        path: '/terms-and-condition',
        Layout: StaticLayout,
    },
    {
        path: '/nri-disclaimer',
        Layout: StaticLayout,
    },
    {
        path: '/investment-disclaimer',
        Layout: StaticLayout,
    },
    {
        path: '/investor-charter',
        Layout: StaticLayout,
    },
    {
        path: '/grievance-redressal',
        Layout: StaticLayout,
    },
    {
        path: '/privacy-policy',
        Layout: StaticLayout,
    },
    {
        path: '/risk-disclosure',
        Layout: StaticLayout,
    },
    {
        path: '/disclaimer',
        Layout: StaticLayout,
    },
    {
        path: '/corporate-bond',
        Layout: StaticLayout,
    },
    {
        path: '/about-us',
        Layout: StaticLayout,
    },
    {
        path: '/team',
        Layout: StaticLayout,
    },
    {
        path: '/careers',
        Layout: StaticLayout,
    },
    // {
    //     path: '/webinars',
    //     Layout: StaticLayout,
    // },
    {
        path: '/accounts',
        Layout: InvestorLayout,
    },
    {
        path: '/email-verify',
        Layout: InvestorLayout,
    },
    {
        path: '/blogs',
        Layout: StaticLayout,
    },
    {
        path: '/bond-directory',
        Layout: StaticLayout,
    },
    {
        path: '/media',
        Layout: StaticLayout,
    },
    {
        path: '/products',
        Layout: ProductPageLayout,
    },
    {
        path: '/investor-ifa-disclaimer',
        Layout: InvestorLayout,
    },
    {
        path: '/create-new-account',
        Layout: NormalLayout,
    },
    {
        path: '/refer-and-earn',
        Layout: InvestorLayout,
    },
    {
        path: '/invitation',
        Layout: NormalLayout,
    },
    {
        path: '/inviteonboardingcomplete',
        Layout: NormalLayout,
    },
    {
        path: '/my-referral',
        Layout: InvestorLayout,
    },
    {
        path: '/credit-evaluation-process',
        Layout: StaticLayout,
    },
    {
        path: '/glossary',
        Layout: StaticLayout,
    },
    {
        path: '/bondfundamentals',
        Layout: StaticLayout,
    },
    {
        path: '/questionnaire',
        Layout: WelcomeLayout,
    },
    {
        path: '/welcome',
        Layout: WelcomeLayout,
    },
    {
        path: '/referral-program',
        Layout: StaticLayout,
    },
    {
        path: '/news-letters',
        Layout: StaticLayout,
    },
    {
        path: '',
        Layout: EmptyLayout,
    },
];

const getLayoutConfig = () => {
    const router = useRouter();
    const path = router.pathname;
    const config = LayoutConfig.find((configItem) =>
        path.includes(configItem.path)
    );

    if (config) return config;
    else return { Layout: EmptyLayout };
};

export { getLayoutConfig };
