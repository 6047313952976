import React from 'react';
const Text = ({
    content,
    isInnerHtml = false,
    htmlTag = 'div',
    id,
    ...property
}) => {
    const fontWeightClass = property?.weight ? `font-${property.weight}` : '';
    const fontSizeClass = property?.size ? `text-${property.size}` : '';
    const className =
        `${fontSizeClass} ${fontWeightClass} ${property.className} `.trim();
    const dataAnimation = property['data-animation'];

    const contentTag = React.createElement(
        htmlTag,
        {
            className: className,
            ...(dataAnimation && { 'data-animation': dataAnimation },
            isInnerHtml && {
                dangerouslySetInnerHTML: { __html: content },
            }),
            id,
            ...property,
        },
        !isInnerHtml ? content : null
    );

    return contentTag;
};

export default Text;
