import Cookies from 'universal-cookie';
import { getWallet } from './jiraaf-safe.service';
import jwtDecode from 'jwt-decode';
import { refreshToken } from '@services/http.service';
import { getAuthCtx, setCookies } from '@http/ssr-http.service';
import { cloneDeep } from 'lodash';

const cookies = new Cookies();
const AUTH_KEY = 'individual';

export const isBrowser = () => typeof window !== 'undefined' && window.localStorage;

export const setAuth = (authObject) => {
    const clonedAuth = cloneDeep(authObject)
    if (!isBrowser()) return console.warn('localStorage is not available');
    if('token' in clonedAuth){
        clonedAuth['exp'] = jwtDecode(clonedAuth.token)?.exp;
        delete clonedAuth['token'];
    }
    
    if('refreshToken' in clonedAuth) delete clonedAuth['refreshToken'];
    const existingAuth = getAuth();
    const authString = JSON.stringify({...existingAuth, ...clonedAuth});
    window.localStorage.setItem(AUTH_KEY, authString);
}

export const getAuth = () => {
  if (!isBrowser()) return null;

  const authString = window.localStorage.getItem(AUTH_KEY);
  if (authString) {
    const authObject = JSON.parse(authString);
    return authObject;
  }
  return null;
};

export const removeAuth = () => {
  if (!isBrowser()) return console.warn('localStorage is not available');
  window.localStorage.removeItem(AUTH_KEY);
};

export const isAuthenticated = async (user, ctx = null) => {
    return user != null && (isBrowser() || user.token) && (await verifyToken(ctx));
};

export const isUnauthorizedRequest = async (auth, ctx = null) => {
    return !auth || !(await isAuthenticated(JSON.parse(auth), ctx));
};

export const getReferralCode = () => {
    if (isBrowser()) {
        const referralCode = localStorage.getItem('REFERRAL_CODE');
        return referralCode ? JSON.parse(referralCode) : null;
    }
    return null;
};

export const getReferralEntityType = () => {
    if (isBrowser()) {
        const referralEntityType = localStorage.getItem('REFERRAL_ENTITY');
        return referralEntityType ? JSON.parse(referralEntityType) : null;
    }
    return null;
};

export const setReferralCode = (ReferralObject) => {
    if (isBrowser()) {
        localStorage.setItem('REFERRAL_CODE', JSON.stringify(ReferralObject));
    }
    return ReferralObject;
};

export const setReferralEntityType = (ReferralObject) => {
    if (isBrowser()) {
        localStorage.setItem('REFERRAL_ENTITY', JSON.stringify(ReferralObject));
    }
    return ReferralObject;
};

export const removeReferralCode = () => {
    if (isBrowser() && localStorage.getItem('REFERRAL_CODE')) {
        localStorage.removeItem('REFERRAL_CODE');
    }
};

export const removeReferralEntityType = () => {
    if (isBrowser() && localStorage.getItem('REFERRAL_ENTITY')) {
        localStorage.removeItem('REFERRAL_ENTITY');
    }
};

// To check if token is valid or not
// Endpoint to the check.
// decode the token, if ext is greater than 30 seconds of now. -> true else false
// Al calls to backend.
export const verifyToken = async (ctx) => {
    try {
        if (ctx) {
            let currentTimestamp = new Date().getTime() / 1000;
            const authCtx = getAuthCtx(ctx);
            const decoded = jwtDecode(authCtx.token);
            const isExpired = decoded.exp < currentTimestamp;
            if (isExpired) {
                const response = await refreshToken(authCtx);
                if (response.status) {
                    setCookies(ctx, { token: response.entity });
                }
                return response.status;
            }
            return !isExpired;
        } else {
            let currentTimestamp = new Date().getTime() / 1000;
            const auth = getAuth();
            const decodedExp = auth.exp;
            const isExpired = decodedExp < currentTimestamp;
            if (isExpired) {
                const response = await refreshToken(auth);
                if (response.status) {
                    setAuth(response.entity);
                }
                return response.status;
            }
            return !isExpired;
        }
    } catch (e) {
        return false;
    }
};

export const isAdmin = getAuth()?.role === 'Admin';
export const isSuperAdmin = () =>
    isAdmin && process.env.NEXT_PUBLIC_SA.includes(getAuth().username);

export const getLogoutSsr = (ctx, redirectUrl = '') => {
    /* 
        ctx.res.setHeader('Cache-Control', 'no-store') sets the Cache-Control header to 'no-store', 
        indicating that the response should not be stored or cached.
        By setting the cache control header in this way, you can prevent caching of the redirect response.
        Issue resolved for duplicate tab redirected to cache route
    */
    if (ctx) ctx.res.setHeader('Cache-Control', 'no-store');
    return {
        redirect: {
            permanent: false,
            destination: redirectUrl ? `/logout?redirect=${redirectUrl}` : `/logout`,
        },
    };
};

export const isWalletActive = async (investorId, ctx = null) => {
    if (investorId) {
        const wallet = await getWallet(investorId, ctx);
        return wallet?.entity?.isActive;
    }
    return false;
};
