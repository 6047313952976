/**
 * buttonType can be "primary"(default), "secondary", "borderless", 'text'
 *
 * buttonSize can be "extraSmall", "small", "regular"(default) or "large"
 *
 * buttonText is a string
 *
 * prefixComponent and suffixComponent - components/icons/images to add
 *
 * isLoading, disabled - boolean (default false)
 *
 * widthClass - tailwind class to specify width (default: w-min)
 *
 * onClick - function to be called when clicked
 *
 * className - any other classes to add to the button element
 */
const Button = ({
    buttonText = '',
    buttonType = 'primary',
    buttonSize = 'regular',
    prefixComponent,
    suffixComponent,
    isLoading = false,
    widthClass,
    onClick,
    disabled = false,
    className = '',
    ...property
}) => {
    const buttonClasses = () => {
        switch (buttonType?.toLowerCase()) {
            case 'secondary':
                return disabled
                    ? 'border-1 border-gray-300 bg-gray-100 text-gray-400'
                    : 'border-1 text-primary-500 border-primary-500';
            case 'primary':
                return disabled
                    ? 'bg-gray-300 text-gray-500'
                    : 'bg-primary-500 text-basicWhite';
            case 'tertiary':
                return disabled
                    ? 'bg-gray-300 text-gray-400'
                    : 'text-primary-500 bg-basicWhite';
            case 'borderless':
            case 'text':
            default:
                return disabled
                    ? 'text-gray-400'
                    : 'bg-transparent text-primary-500';
        }
    };

    const hoverStateClasses = () => {
        if (disabled || isLoading) return '';
        switch (buttonType?.toLowerCase()) {
            case 'primary':
                return 'hover:bg-primary-400';
            case 'secondary':
                return 'hover:bg-primary-50';
            case 'tertiary':
                return 'hover:bg-secondary-50 hover:text-primary-700';
            case 'borderless':
            case 'text':
            default:
                return 'hover:text-primary-700';
        }
    };

    const pressedStateClasses = () => {
        if (disabled || isLoading) return '';
        switch (buttonType?.toLowerCase()) {
            case 'primary':
                return 'active:bg-primary-700 active:text-basicWhite';
            case 'secondary':
                return 'active:bg-primary-100 active:text-primary-700';
            case 'tertiary':
                return 'active:bg-secondary-100 active:text-primary-700';
            case 'text':
            case 'borderless':
            default:
                return 'active:text-primary-700';
        }
    };

    const paddingClasses = () => {
        if (['text'].includes(buttonType?.toLowerCase())) return '';
        switch (buttonSize?.toLowerCase()) {
            case 'extrasmall':
                return buttonType === 'secondary'
                    ? 'px-[11px] py-[7px]'
                    : 'px-3 py-2';
            case 'small':
                return buttonType === 'secondary'
                    ? 'px-[15px] py-[9px]'
                    : 'px-4 py-[10px]';
            case 'large':
            case 'regular':
            default:
                return buttonType === 'secondary'
                    ? 'px-[19px] py-[11px]'
                    : 'px-5 py-3';
        }
    };

    const spacingClass = () => {
        switch (buttonSize?.toLowerCase()) {
            case 'extrasmall':
                return 'gap-x-1';
            case 'small':
            case 'regular':
                return 'gap-x-1.5';
            case 'large':
            default:
                return 'gap-x-2';
        }
    };

    const borderRadiusClass = () => {
        if (['borderless', 'text'].includes(buttonType?.toLowerCase()))
            return '';
        switch (buttonSize?.toLowerCase()) {
            case 'extrasmall':
            case 'small':
            case 'large':
            case 'regular':
            default:
                return 'rounded-lg';
        }
    };

    const typographyClass = () => {
        switch (buttonSize?.toLowerCase()) {
            case 'large':
                return 'p3-medium';
            case 'extrasmall':
                return ['tertiary', 'text'].includes(buttonType?.toLowerCase())
                    ? 'p5-medium'
                    : 'p4-medium';
            case 'small':
            case 'regular':
            default:
                return 'p4-medium';
        }
    };

    const loaderClass = () => {
        switch (buttonType?.toLowerCase()) {
            case 'primary':
                return 'button-loader-white';
            case 'borderless':
            case 'text':
            case 'secondary':
            case 'tertiary':
            default:
                return 'button-loader-primary';
        }
    };

    const buttonClicked = (e) => {
        if (!disabled && !isLoading && onClick) onClick(e);
    };

    return (
        <button
            onClick={buttonClicked}
            disabled={disabled || isLoading}
            className={`flex flex-row justify-center transition-all ease-in-out items-center whitespace-nowrap ${
                widthClass ? widthClass : 'w-min'
            } ${
                !disabled && !isLoading ? 'cursor-pointer' : 'cursor-default'
            } ${borderRadiusClass()} ${widthClass} ${paddingClasses()} ${spacingClass()} ${buttonClasses()} ${hoverStateClasses()} ${pressedStateClasses()} ${className}`}
            {...property}
        >
            {prefixComponent && (
                <div className="flex items-center justify-center object-contain w-4 h-4">
                    {prefixComponent}
                </div>
            )}

            {buttonText && <p className={typographyClass()}>{buttonText}</p>}

            {isLoading ? (
                <div className={loaderClass()} />
            ) : (
                suffixComponent && (
                    <div className="flex items-center justify-center object-contain w-4 h-4">
                        {suffixComponent}
                    </div>
                )
            )}
        </button>
    );
};

export default Button;
